<template>
  <form class="import-modal-priority-destination">
    <div class="import-modal-priority-destination__row">
      <p class="custom-form__label custom-form__label--mb-8 required">Год</p>
      <ComponentInput
        type="number"
        v-model="item.year"
        mod="rectangular"
        required
        :error="errorYear || errorYearMinVal"
      />
      <div class="invalid-msg" v-if="errorYear">Введите корректный год</div>
      <div class="invalid-msg" v-else-if="errorYearMinVal">От 1999</div>
    </div>
    <div class="import-modal-priority-destination__row">
      <div class="label-field">Месяц <span>*</span></div>
      <SelectMultiComponent
        v-model="item.month"
        :options="getMonth"
        :classSelect="['multiselect--rectangular', { error: v$.item.month.$error }]"
        required
      />
      <div class="invalid-msg" v-if="v$.item.month.$error">Поле обязательно для заполнения</div>
    </div>
    <div class="import-modal-priority-destination__row">
      <div class="form-group">
        <label class="control-label">Файл</label>
        <input
          type="file"
          id="file-input"
          :accept="accept"
          class="form-control"
          @change="fileChanged($event)"
          required
          ref="inputFile"
        />
        <div class="invalid-msg" v-if="v$.item.file.$error">Поле обязательно для заполнения</div>
      </div>
    </div>
    <div class="import-modal-priority-destination__row">
      <ButtonComponent
        @click.prevent.stop="onLoad"
        mod="gradient-bg"
        class="button-redesign--map-apply"
        :disabled="disabledButton"
        >{{ disabledButton ? 'Загрузка' : 'Загрузить' }}</ButtonComponent
      >
      <ButtonComponent mod="border" @click.prevent.stop="onClose">Отмена</ButtonComponent>
    </div>
  </form>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength, minLength, minValue, required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';

  export default {
    name: 'ImportModalPriorityDestination',
    emits: ['closeModal'],
    components: {
      ComponentInput,
      ButtonComponent,
      SelectMultiComponent,
    },
    data() {
      return {
        item: {
          year: '',
          month: null,
          file: null,
        },
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        disabledButton: false,
      };
    },
    computed: {
      getMonth() {
        return Constants.monthList.map((i) => ({ value: i.id, label: i.text }));
      },

      errorYear() {
        return this.v$.item.year.maxLength.$invalid || this.v$.item.year.minLength.$invalid;
      },

      errorYearMinVal() {
        return this.v$.item.year.minValue.$invalid;
      },
    },
    methods: {
      fileChanged(e) {
        this.item.file = e.target.files[0];
      },

      onLoad() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
        this.disabledButton = true;
        this.$emit('loadTable', this.item);
      },

      onClose() {
        this.$emit('closeModal');
      },

      /**@public */
      // используется при успешном выполнении запроса
      // в компоненте PriorityImportAndImportDestination.vue
      reset() {
        this.item.year = '';
        this.item.month = null;
        this.item.file = null;
        this.$refs.inputFile.value = '';
        this.v$.$reset();
      },
    },
    watch: {
      item: {
        handler() {
          this.error = false;
        },
        deep: true,
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          year: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(4),
            minValue: minValue(1999),
          },
          month: { required },
          file: { required },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';
  .import-modal-priority-destination {
    &__row {
      margin-bottom: 10px;
    }
  }
</style>
