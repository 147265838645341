<template>
  <div class="popup-download-status">
    <h4 class="title title--h2 title--projects-statistics">Данные за {{ getMonth() }} {{ year }} успешно загружены.</h4>
    <template v-if="errors.length">
      <p><b>Для корректной загрузки файла, добавьте не найденные значения в таблицу "Маппинг стран"!</b></p>
      <p>Ошибки возникшие при загрузке:</p>
      <ul>
        <li v-for="(item, index) in errors" :key="`error-${index}`">{{ item }}</li>
      </ul>
    </template>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  export default {
    name: 'PopupDownloadStatus',
    props: {
      month: {
        type: [String, Number],
        default: '',
      },
      year: {
        type: [String, Number],
        default: '',
      },
      errors: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      getMonth() {
        return Constants.monthList[this.month - 1]?.text || '';
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/title.scss';

  p,
  li {
    font: $fira-16;
    margin-bottom: 10px;
  }

  li {
    padding-left: 24px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: 0;
      top: 7px;
      border-radius: 50%;
      background-color: $blue;
    }
  }

  b {
    font-weight: 700;
  }
</style>
