<template>
  <div class="container-page page-priority-import-import-destination">
    <p class="title-inner">Приоритетный импорт и импорт по назначению</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div class="page-priority-import-import-destination__switch">
      <RedesignedSwitchComponent
        textTrue="Приоритетный импорт"
        textFalse="Импорт по назначению"
        @switchHandler="ispriorityImport = !ispriorityImport"
        class="switch-back--big"
      />
    </div>
    <div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th class="column-year">Год</th>
              <th class="column-month">Месяц</th>
              <th class="column-type">Тип</th>
              <th class="column-country">Страна</th>
              <th class="column-price">Стоимость, млн. $</th>
              <th class="column-share">Доля, %</th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  type="year"
                  v-model="filter.year"
                  placeholder="Год"
                  @valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="monthOption"
                  :select2-settings="select2Settings"
                  v-model="filter.month"
                  placeholder="Месяц"
                  @valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="getTypeImportSelect"
                  :select2-settings="select2Settings"
                  v-model="filter.importKindId"
                  placeholder="Тип"
                  @valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="getCountriesSelect"
                  :select2-settings="select2Settings"
                  v-model="filter.countryId"
                  placeholder="Страна"
                  @valueChangedDebounced="changeFilter"
                />
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="getPageInfo.items?.length && !getStateLoadPage">
            <tr v-for="item in getPageInfo.items" :key="item.id">
              <td>{{ item.year }}</td>
              <td>{{ monthOption[item.month - 1].text }}</td>
              <td>{{ item.importKindName }}</td>
              <td>{{ item.country?.nameFull || item.country?.name }}</td>
              <td>{{ item.cost.toFixed(2) }} $</td>
              <td>{{ (item.part * 100).toFixed(2) }} %</td>
            </tr>
          </tbody>
          <tbody v-else-if="getStateLoadPage">
            <tr>
              <td class="text-center" colspan="7">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="7">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="getPageInfo.pagesTotal > 1">
        <Pagination
          :page-count="getPageInfo.pagesTotal"
          :current-page="getPageInfo.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
    <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal('importModal')">
      <ImportModalPriorityDestination
        @closeModal="closeModal('importModal')"
        @loadTable="loadTable"
        ref="modalPriorityDestination"
      />
    </ModalComponent>

    <ModalComponent
      title="Статус загрузки файла"
      content-class="modal--new"
      width="700"
      :themeLigth="true"
      v-model="downloadStatusModal"
      @close="closeModal('downloadStatusModal')"
      @update:modelValue="downloadStatusModal"
    >
      <PopupDownloadStatus v-bind="infoPopupDownloadStatus" />
    </ModalComponent>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import ModalComponent from '@/components/modals/ModalComponent';

  import ImportModalPriorityDestination from '../components/ImportModalPriorityDestination';
  import PopupDownloadStatus from '../components/PopupDownloadStatus';
  import {
    PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME,
    PRIORITY_IMPORT_DESTINATION_IMPORT_GETTERS_NAME,
    PRIORITY_IMPORT_DESTINATION_IMPORT_MUTATIONS_NAME,
  } from '../store/priorityImportAndImportDestination';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'PriorityImportAndImportDestination',
    components: {
      DefaultFilter,
      ButtonStock,
      FilterInput,
      ModalComponent,
      ImportModalPriorityDestination,
      RedesignedSwitchComponent,
      PopupDownloadStatus,
    },
    mixins: [filtermanager],
    data() {
      return {
        select2Settings: Constants.select2Settings,
        importInProgress: false,
        importModal: false,
        filter: {
          year: null,
          month: null,
          importKindId: null,
          countryId: null,
        },
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        monthOption: Constants.monthList,
        ispriorityImport: true,
        downloadStatusTitle: '',
        downloadStatusModal: false,
        infoPopupDownloadStatus: {
          month: 0,
          year: 0,
          errors: [],
        },
      };
    },
    async created() {
      await this.countriesGet();
      this.loadTypeImport();
      this.loadPage();
    },
    computed: {
      ...mapGetters({
        getCountriesSelect: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
        getTypeImportSelect: DIRECTIVES_GETTERS_NAME.getTypeImportSelect,
      }),
      ...mapGetters({
        getPageInfo: PRIORITY_IMPORT_DESTINATION_IMPORT_GETTERS_NAME.getPageInfo,
        getStateLoadPage: PRIORITY_IMPORT_DESTINATION_IMPORT_GETTERS_NAME.getStateLoadPage,
      }),
    },
    methods: {
      ...mapActions({
        countriesGet: DIRECTIVES_ACTIONS_NAME.countriesGet,
        getTypeImport: DIRECTIVES_ACTIONS_NAME.getTypeImport,
      }),
      ...mapActions({
        getInfoPage: PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME.getInfoPage,
        loadFile: PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME.loadFile,
      }),

      ...mapMutations({
        changeStateLoadPage: PRIORITY_IMPORT_DESTINATION_IMPORT_MUTATIONS_NAME.changeStateLoadPage,
        setInfoPage: PRIORITY_IMPORT_DESTINATION_IMPORT_MUTATIONS_NAME.setInfoPage,
        addInfoPage: PRIORITY_IMPORT_DESTINATION_IMPORT_MUTATIONS_NAME.addInfoPage,
      }),

      importExcel() {
        this.importModal = true;
      },

      async loadTypeImport() {
        await this.getTypeImport({ ispriorityImport: this.ispriorityImport });
      },

      async loadPage(append = false) {
        this.changeStateLoadPage(true);
        const res = await this.getInfoPage({
          ispriorityImport: this.ispriorityImport,
          ...this.filter,
          ...this.request,
        });

        if (res.success) {
          if (append) {
            this.addInfoPage(res);
          } else {
            this.setInfoPage(res);
          }
        }
      },

      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },

      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },

      closeModal(type) {
        this[type] = false;
        if (type === 'importModal') {
          this.$refs.modalPriorityDestination.reset();
          this.$refs.modalPriorityDestination.disabledButton = false;
        }
      },

      async loadTable(item) {
        const res = await this.loadFile(item);
        if (res.success) {
          const { data } = res;
          this.infoPopupDownloadStatus = {
            month: item.month,
            year: item.year,
            errors: data.errors,
          };
          this.closeModal('importModal');
          this.downloadStatusModal = true;
          this.onPageChange(1);
          this.$refs.modalPriorityDestination.reset();
          this.$refs.modalPriorityDestination.disabledButton = false;
        } else {
          Constants.alert.fire('Загрузка', Constants.errorDownloadFile, 'error');
          this.$refs.modalPriorityDestination.disabledButton = false;
        }
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
    },
    watch: {
      ispriorityImport() {
        this.request.pageNumber = 1;
        this.loadTypeImport();
        this.loadPage();
      },
    },
  };
</script>

<style scoped lang="scss">
  .page-priority-import-import-destination {
    &__switch {
      margin-bottom: 24px;
    }

    .column-year,
    .column-price,
    .column-share,
    .column-month {
      min-width: 150px;
      max-width: 150px;
    }
    .column-type,
    .column-country {
      min-width: 200px;
      max-width: 200px;
    }
  }
</style>
